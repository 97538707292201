import { pickedApiRequest } from './request'

export default {
  /**
   * Organisation’s candidate attempts
   *
   * @param {Object} query
   * @return {Array}
   */
  index (organisationId, query) {
    return pickedApiRequest.get('assessment/organisations/' + organisationId + '/candidate-attempts', {
      params: query
    })
      .then(response => {
        return response.data
      })
  }
}
