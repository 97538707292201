<template>
  <div class="w-full mx-auto py-8">
    <Results />
  </div>
</template>

<script>
import Results from '@components/Candidates/Results'

export default {
  page: {
    title: 'Results'
  },

  components: {
    Results
  }
}
</script>
