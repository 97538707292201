<template>
  <div v-if="state === states.READY">
    <BasePageHeader>
      <div>
        <BasePageBreadcrumbs :breadcrumbs="breadcrumbs"></BasePageBreadcrumbs>
        <BasePageHeading>Results</BasePageHeading>
      </div>
      <BasePageActions>
        <ExportButton
          v-if="attempts && attempts.length > 0"
          type="results"
        />
      </BasePageActions>
    </BasePageHeader>

    <BasePageRefine>
      <SearchBox
        :search-query="searchQuery"
        placeholder="Search candidates"
        @search="onSearch($event)"
      >
      </SearchBox>
      <!-- @TODO 2023-02-08 Remove whene no longer required -->
      <!-- <CustomSelect
        placeholder="All assessments"
        :options="openJobs"
        @selected="filterJob($event)"
      /> -->
    </BasePageRefine>

    <BaseTabBar>
      <BaseTabs>
      </BaseTabs>
    </BaseTabBar>

    <div v-if="attempts">
      <div v-if="attempts.length > 0">
        <div class="max-w-screen-xl mx-auto">
          <div class="px-6">
            <ResultsTable
              :show-search="false"
              :columns="columns"
              :table-data="attempts"
              :pagination-data="paginationData"
              :sort-data="sortData"
              @pagechanged="onPageChange($event)"
              @sort="onSort($event)"
            />
          </div>
        </div>
      </div>
      <div
        v-else
        class="max-w-screen-xl px-6 mx-auto"
      >
        <div v-if="searchQuery">
          <p class="py-6 text-center bg-white">
            Can’t find any candidates using “{{ searchQuery }}”
          </p>
        </div>
        <div v-else>
          <JobsEmpty />
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="state === states.LOADING">
    <BasePageHeader>
      <div>
        <BasePageBreadcrumbs :breadcrumbs="breadcrumbs"></BasePageBreadcrumbs>
        <BasePageHeading>Results</BasePageHeading>
      </div>
    </BasePageHeader>
    <BaseLoader />
  </div>
  <div v-else-if="state === states.ERROR">
    <BaseErrorBlock />
  </div>
</template>

<script>
// @TODO 2023-02-08 Remove whene no longer required
// import CustomSelect from '@components/CustomSelect'
import JobsEmpty from '@components/Jobs/JobsEmpty'
import ResultsTable from '@components/Candidates/ResultsTable'
import SearchBox from '@components/Candidates/SearchBox'
import ExportButton from '@components/ExportButton'

import organisationCandidatesAttemptsApi from '@api/organisationCandidateAttempts'
import { mapGetters } from 'vuex'
import states from '@api/states'

export default {
  components: {
    // @TODO 2023-02-08 Remove whene no longer required
    // CustomSelect,
    JobsEmpty,
    ResultsTable,
    SearchBox,
    ExportButton
  },

  data() {
    return {
      states,

      jobId: null,
      jobName: null,
      searchQuery: '',
      searchSortBy: null,
      searchSortOrder: null,

      columns: [
        { key: 'name', sortable: false },
        { key: 'test', sortable: false },
        { key: 'result', sortable: true },
        { key: 'average', sortable: false },
        { key: 'answered', sortable: true },
        { key: 'time', sortable: true },
        { key: 'createdAt', sortable: true }
      ],

      attempts: null,

      paginationData: {
        currentPage: 1,
        perPage: 50,
        totalPages: null,
        totalItems: 0
      },

      sortData: {
        key: null,
        order: null
      },

      filters: {
        examSlugs: [],
        customExamSlugs: []
      }
    }
  },

  computed: {
    ...mapGetters({
      haveJobsLoaded: 'jobs/haveJobsLoaded',
      organisationName: 'organisations/name',
      openJobs: 'jobs/openJobs',
      organisationId: 'employers/organisationId'
    }),

    /**
     * @return {Array}
     */
    breadcrumbs() {
      return [
        {
          name: this.organisationName
        }
      ]
    },

    /**
     * @return {string}
     */
    state() {
      if (this.error) {
        return states.ERROR
      }
      if (!this.attempts) {
        return states.LOADING
      }

      return states.READY
    },

    /**
     * Returns the current filters for the search
     *
     * @return {Object}
     */
    query() {
      return {
        job_id: this.jobId,
        examSlugs: this.filters.examSlugs,
        customExamSlugs: this.filters.customExamSlugs,
        page: this.paginationData.currentPage,
        per_page: this.paginationData.perPage,
        search: this.searchQuery,
        sort_by: this.searchSortBy,
        sort_order: this.searchSortOrder
      }
    }
  },

  watch: {
    filters: {
      handler() {
        this.fetchCandidateAttempts()
      },
      deep: true
    }
  },

  mounted() {
    if (!this.haveJobsLoaded) {
      this.$store.dispatch('jobs/getJobs')
    }
    this.fetchCandidateAttempts()
  },

  methods: {
    /**
     * Fetch the candidates based on the current query
     */
    fetchCandidateAttempts() {
      this.attempts = null
      return organisationCandidatesAttemptsApi.index(this.organisationId, this.query)
        .then(data => {
          this.attempts = data.data
          if (data.meta) {
            this.paginationData.currentPage = data.meta.current_page
            this.paginationData.totalItems = data.meta.total
            this.paginationData.totalPages = data.meta.last_page
            // @NOTE 2019-12-19 Add in if required
            // this.paginationData.from = data.meta.from
            // this.paginationData.to = data.meta.to
            this.sortData.key = data.meta.sort_by
            this.sortData.order = data.meta.sort_order
          }
        })
        .catch(error => {
          this.attempts = []
          throw error
        })
    },

    /**
     * Filter has been chosen for a job
     *
     * @param {Object} job
     */
    filterJob(job) {
      this.jobId = job.id
      this.jobName = job.name
      this.fetchCandidateAttempts()
    },

    /**
     * When the page changes...
     *
     * @param {Number} page
    */
    onPageChange(page) {
      this.paginationData.currentPage = page
      this.fetchCandidateAttempts()
    },

    /**
     * User selects a sort by
     *
     * @param {string} key
    */
    onSort({ key, order }) {
      this.searchSortBy = key
      this.searchSortOrder = order
      this.fetchCandidateAttempts()
    },

    /**
     * Replace search query and trigger fetch attempts
     *
     * @param {string}
     */
    onSearch(query) {
      this.searchQuery = query
      this.fetchCandidateAttempts()
    }
  }
}
</script>
