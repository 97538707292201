<template>
  <component
    :is="tag"
    class="w-full"
  >
    <template v-if="!maskedAttributes.includes('name')">
      <slot />
    </template>
    <template v-else>
      Anonymous
    </template>
  </component>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    tag: {
      type: String,
      default: 'p'
    }
  },

  computed: {
    ...mapGetters({
      maskedAttributes: 'organisations/maskedAttributes'
    })
  }
}
</script>
